<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              Editing {{ deliveryReceipt.stringID }}
            </h1>
            <p class="log_info">
              Created by {{ deliveryReceipt.createdBy ? deliveryReceipt.createdBy.name : '' }} on {{ dateFormatWithTime(deliveryReceipt.createdAt) }}<br>Last updated on {{ dateFormatWithTime(deliveryReceipt.updatedAt) }}
            </p>
            <p>Please enter the details and information that you have upon receipt of goods and/or services.</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="deliveryReceiptEditForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="checkForFileUpload">
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              <small>section 1</small>
              Delivery Receipt Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Date"
                vid="date"
                rules="required"
              >
                <b-form-group
                  label="Date Received*"
                  label-for="h-event-time-slot-start"
                  label-cols-md="3"
                >
                  <b-input-group>
                    <flat-pickr
                      id="h-event-time-slot-start"
                      v-model="receivedDate"
                      class="form-control flat-pickr-group"
                      :config="flatPickrConfig"
                      @input="receivedDateValidation == true ? receivedDateValidation = false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="CalendarIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="receivedDateValidation"
                    class="text-danger"
                  >
                    {{ receivedDateError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Time Received"
                vid="time"
                rules="required"
              >
                <b-form-group
                  label="Time Received*"
                  label-for="h-event-time-slot-receivedTime"
                  label-cols-md="3"
                >
                  <b-input-group>
                    <flat-pickr
                      id="h-event-time-slot-receivedTime"
                      v-model="receivedTime"
                      class="form-control flat-pickr-group"
                      :config="{ wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true }"
                      @input="receivedTimeValidation == true ? receivedTimeValidation = false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="CalendarIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="receivedTimeValidation"
                    class="text-danger"
                  >
                    {{ receivedTimeError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-9">
              <b-form-group
                label="Remarks"
                label-for="h-event-remarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  vid="remarks"
                  rules=""
                >
                  <b-form-textarea
                    id="h-event-remarks"
                    v-model="remarks"
                    placeholder="E.g. Where is the received goods stored at? Any remarks from the delivery?"
                    :state="(errors.length > 0 || remarksValidation) ? false : null"
                    name="remarks"
                    @input="remarksValidation == true ? remarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="remarksValidation"
                    class="text-danger"
                  >
                    {{ remarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-3" />
          </b-row>
        </b-card>
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              <small>section 2</small>
              Supporting Documents, Files and Images (Required)
            </h3>
          </template>
          <b-row>
            <b-col
              cols="12"
            >
              <vue-dropzone
                id="event-images"
                ref="myVueDropzone"
                :options="dropzoneOptions"
                :use-custom-slot="true"
                @vdropzone-files-added="processFile"
                @vdropzone-removed-file="fileRemoved"
                @vdropzone-error="handleError"
              >
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">
                    Drop files here or click to upload
                  </h3>
                  <div class="subtitle">
                    Image should not be bigger than 20 mb. JPEG or PNG only
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mt-2"
                  >
                    Upload file
                  </b-button>
                </div>
              </vue-dropzone>
            </b-col>
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              @click="resolveCancelRoute"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BInputGroupAppend, BFormTextarea, BForm, BButton, BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import flatPickr from 'vue-flatpickr-component'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    BForm,
    BButton,
    BNav,

    flatPickr,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: store.state.auth.userData,
      deliveryReceipt: {},
      receivedDate: '',
      receivedTime: '',
      remarks: '',
      attachments: [],
      receivedDateError: 'Valid date is required',
      receivedDateValidation: false,
      receivedTimeError: 'Valid time is required',
      receivedTimeValidation: false,
      attachmentsError: 'Valid attachments is required',
      attachmentsValidation: false,
      remarksError: 'Valid remarks is required',
      remarksValidation: false,
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png',
        maxFilesize: 20,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },

      // validation rules
      required,
    }
  },
  async beforeRouteEnter(to, from, next) {
    if (to.query.from && to.query.from === 'my-dr') {
      // eslint-disable-next-line no-param-reassign
      to.meta.navActiveLink = 'purchasing-my-delivery-receipts-index'
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const breadcrumb = [
        {
          text: 'My Delivery Receipts',
          to: '/purchasing/my-delivery-receipts/all-receipts',
        },
        {
          text: 'Editing Delivery Receipt',
          active: true,
        },
      ]
      store.commit('breadcrumb/ADD_BREADCRUMB', breadcrumb)
    } else {
      // eslint-disable-next-line no-param-reassign
      to.meta.navActiveLink = 'purchasing-delivery-receipts-index'
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const breadcrumb = [
        {
          text: 'Delivery Receipts',
          to: '/purchasing/delivery-receipts/all-receipts',
        },
        {
          text: 'Editing Delivery Receipt',
          active: true,
        },
      ]
      store.commit('breadcrumb/ADD_BREADCRUMB', breadcrumb)
    }
    next()
  },
  created() {
    this.$http.get(`purchase/delivery-receipts/${this.$route.params.id}`)
      .then(response => {
        this.deliveryReceipt = response.data.data
        this.remarks = response.data.data.remarks || ''
        this.receivedDate = this.dateFormat(response.data.data.receivedDate) || ''
        this.receivedTime = this.twentyFourHourTimeFormatFromDateString(response.data.data.receivedDate) || ''

        response.data.data.attachments.forEach(arrayItem => {
          const file = {
            size: (arrayItem.size * 1024 * 1024), name: arrayItem.name, type: arrayItem.type, description: arrayItem.description,
          }
          this.$refs.myVueDropzone.manuallyAddFile(file, arrayItem.data)
          this.attachments.push(arrayItem)
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    processFile(file) {
      const acceptedTypes = ['image/jpeg', 'image/png']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 20 * 1024 * 1024) {
            const exist = this.attachments.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          }
        }
      })
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.attachments = this.attachments.filter(element => element.data !== file.dataURL)
      } else {
        this.attachments = this.attachments.filter(element => element.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    checkForFileUpload() {
      if (this.attachments.length > 0) {
        this.submitForm()
      } else {
        this.$swal({
          title: 'Some Details are Missing',
          html: 'Please upload or take a photo of the Delivery Order before submitting this form. Without the delivery order, your colleagues will not be able to proceed on to checking the goods.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/question.png'),
          imageWidth: 80,
          imageHeight: 80,
          imageAlt: 'Custom Icon',
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: 'Back to Editing',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    submitForm() {
      this.$refs.deliveryReceiptEditForm.validate().then(success => {
        if (success) {
          if (!this.deliveryReceipt.purchaseOrder) {
            this.$swal({
              title: 'Link to a Purchase Order?',
              html: 'If you have information on the relevant Purchase Order, you can choose to link to this Delivery Receipt (this is optional).',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/success.png'),
              reverseButtons: true,
              showCancelButton: true,
              showCloseButton: true,
              allowOutsideClick: false,
              cancelButtonText: 'No, just save and submit',
              confirmButtonText: 'Yes, link to PO now (optional)',
              customClass: {
                confirmButton: 'btn btn-primary ml-1',
                cancelButton: 'btn btn-outline-primary',
              },
              buttonsStyling: false,
              width: '600px',
            })
              .then(result => {
                if (result.dismiss && result.dismiss === 'close') {
                  //
                } else {
                  // eslint-disable-next-line no-lonely-if
                  if (result.value) {
                    this.submitData(true)
                  } else {
                    this.submitData(false)
                  }
                }
              })
          } else {
            this.submitData(false)
          }
        }
      })
    },
    resolveCancelRoute() {
      const readPermision = this.user.permissions.find(o => (o.action === 'read' && o.subject === 'DeliveryReceipt') || (o.action === 'manage' && o.subject === 'all'))
      const myPermision = this.user.permissions.find(o => (o.action === 'my-department-delivery-receipt-read' && o.subject === 'DeliveryReceipt') || (o.action === 'my-delivery-receipt-read' && o.subject === 'DeliveryReceipt'))
      if (readPermision) {
        this.$router.push({ name: 'purchasing-delivery-receipts-index', params: { type: 'all-receipts' } })
      } else if (myPermision) {
        this.$router.push({ name: 'purchasing-my-delivery-receipts-index', params: { type: 'all-receipts' } })
      } else {
        this.$router.replace(this.user.userRedirectURL || '/')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'You do not have permission to view this page',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    submitData(linkToPO = false) {
      const formData = new FormData()

      formData.append('receivedDate', this.receivedDate)
      formData.append('receivedTime', this.receivedTime)
      formData.append('remarks', this.remarks)
      formData.append('attachments', JSON.stringify(this.attachments))

      this.$http.patch(`purchase/delivery-receipts/${this.$route.params.id}/update`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(() => {
          if (linkToPO) {
            const linkPermision = this.user.permissions.find(o => (o.action === 'link-to-po' && o.subject === 'DeliveryReceipt') || (o.action === 'manage' && o.subject === 'all'))
            if (linkPermision) {
              this.$router.push({ name: 'purchasing-delivery-receipts-link-to-po', params: { id: this.$route.params.id } })
            } else {
              this.$router.replace(this.user.userRedirectURL || '/')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'You do not have permission to view this page',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          } else {
            this.$swal({
              title: 'Successfully Updated',
              html: 'This Delivery Receipt has been updated.',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/save.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              showCancelButton: false,
              allowOutsideClick: false,
              confirmButtonText: 'Okay',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.value) {
                  const readPermision = this.user.permissions.find(o => (o.action === 'read' && o.subject === 'DeliveryReceipt') || (o.action === 'manage' && o.subject === 'all'))
                  const myPermision = this.user.permissions.find(o => (o.action === 'my-department-delivery-receipt-read' && o.subject === 'DeliveryReceipt') || (o.action === 'my-delivery-receipt-read' && o.subject === 'DeliveryReceipt'))
                  if (readPermision) {
                    this.$router.push({ name: 'purchasing-delivery-receipts-index', params: { type: 'all-receipts' } })
                  } else if (myPermision) {
                    this.$router.push({ name: 'purchasing-my-delivery-receipts-index', params: { type: 'all-receipts' } })
                  } else {
                    this.$router.replace(this.user.userRedirectURL || '/')
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'You do not have permission to view this page',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                          },
                        })
                      })
                  }
                }
              })
          }
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'receivedDate') {
                this.receivedDateError = validationError.msg
                this.receivedDateValidation = true
              } else if (validationError.param === 'receivedTime') {
                this.receivedTimeError = validationError.msg
                this.receivedTimeValidation = true
              } else if (validationError.param === 'remarks') {
                this.remarksError = validationError.msg
                this.remarksValidation = true
              } else if (validationError.param === 'attachments') {
                this.attachmentsError = validationError.msg
                this.attachmentsValidation = true
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })

            const items = document.getElementsByClassName('text-danger')
            window.scrollTo({
              top: items[0].offsetTop,
              behavior: 'smooth',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },
}
</script>
